<!--
 * @Author: zhangyutong
 * @Date: 2021-09-14 12:37:00
 * @LastEditTime: 2022-04-08 14:34:23
 * @LastEditors: Please set LastEditors
 * @FilePath: \lightning-web\src\views\social\components\newSocial.vue
-->
<!--  -->
<template>
  <div class="">
    <el-drawer
      :title="$t('c633')"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      size="845px"
    >
      <div class="content">
        <div class="subtitle">{{$t('c805')}}</div>
        <div class="img-box">
          <svg
            class="icon"
            aria-hidden="true"
            @click="addPost('facebook')"
            v-show="this.facebookList.length !== 0"
          >
            <use href="#icon-facebook_white"></use>
          </svg>
          <svg
            class="icon"
            aria-hidden="true"
            @click="linkAcount('facebook')"
            v-show="this.facebookList.length === 0"
          >
            <use href="#icon-facebook"></use>
          </svg>
          <svg
            class="icon"
            aria-hidden="true"
            @click="addPost('twitter')"
            v-show="this.twitterList.length !== 0"
          >
            <use href="#icon-twitter_white"></use>
          </svg>
          <svg
            class="icon"
            aria-hidden="true"
            @click="linkAcount('twitter')"
            v-show="this.twitterList.length === 0"
          >
            <use href="#icon-twitter"></use>
          </svg>
          <svg
            class="icon"
            aria-hidden="true"
            @click="addPost('linkedIn')"
            v-show="this.linkedInList.length !== 0"
          >
            <use href="#icon-linkedIn_white"></use>
          </svg>
          <svg
            class="icon"
            aria-hidden="true"
            @click="linkAcount('linkedIn')"
            v-show="this.linkedInList.length === 0"
          >
            <use href="#icon-Linkedin"></use>
          </svg>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import * as marketChannel from "./api";
export default {
  components: {},
  data() {
    return {
      drawer: false,
      direction: "rtl",
      linkedInList:[],
      facebookList:[],
      twitterList:[],
    };
  },
  created() {
    this.init();
  },
  //方法集合
  methods: {
    init() {
      let obj = {
        orgid: this.$store.state.userInfoObj.orgId,
        socialtype: this.selectAccountKey,
      };
      marketChannel.getScoialUserinfo(obj).then((res) => {
        if (res.result) {
          if (res.data.list && res.data.list.length !== 0) {
            let linkedInList = [];
            let facebookList = [];
            let twitterList = [];
            res.data.list.forEach((item) => {
              item.checked = true;
              if (item.socialtype === "linkedIn") {
                linkedInList.push(item);
              } else if (item.socialtype === "facebook") {
                facebookList.push(item);
              } else {
                twitterList.push(item);
              }
            });
            this.linkedInList = linkedInList;
            this.facebookList = facebookList;
            this.twitterList = twitterList;
          } else {
            // this.dialogVisible = true;
          }
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
    },
    linkAcount(type) {
      this.$emit("linkAcount", type);
    },
    // 新建
    addPost(imgFlg) {
      this.$emit("openPost", "create", { socialType: imgFlg });
    },
    
    newSocial() {
      this.init()
      this.drawer = true;
    },
    handleClose() {
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //     done();
      //   })
      //   .catch(_ => { });
      this.drawer = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.icon {
  display: inline-block;
}
::v-deep .el-drawer__body {
  background: #f0f0f0;
}
::v-deep .el-drawer__header {
  background: linear-gradient(90deg, #2d6cfc 0%, #6c98fd 100%);
  font-family: Helvetica;
  font-size: 18px;
  color: #fff;
  letter-spacing: 0;
  font-weight: bold;
  padding: 20px;
  margin-bottom: 0px;
}
.content {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dedcda;
  width: 60%;
  margin: 40px auto 0px;
  .subtitle {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #3e3c3c;
    letter-spacing: 0;
  }
  .img-box {
    width: 40%;
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    cursor: pointer;
    .icon {
      width: 40px;
      height: 40px;
    }
  }
}
</style>
