<template>
  <div class="wrapper">
    <div class="main">
      <p class="title">
        <!-- This is how your post will display on {{postData.socialtype}}. Slight differences may occur if {{postData.socialtype}} makes any layout adjustments. -->
        {{$t('c977',[postData.socialtype,postData.socialtype])}}
      </p>
      <el-card class="messageBox">
        <div class="headBox">
          <div class="logoBox">
            <div class="logoImg" v-for="(item,index) in postData.checkedList" :key="index">
              <img
                v-if="item.checked==true"
                :src="`data:image/png;base64,${item.socialmediapicture}`"
                class="icon"
              />
            </div>
            <div
              class="userBox"
              :style="
                postData.socialtype === 'twitter' ? 'flex-direction: unset' : ''
              "
            >
              <b>{{showName(postData.checkedList)}}</b>
              <span v-show="postData.socialtype === 'twitter'"
                >&ensp;·&ensp;</span
              >
              <p class="dateText">Just Now</p>
            </div>
          </div>
          <div
            class="cursor-pointer actionIcon"
            v-show="postData.socialtype !== 'twitter'"
          >
            <svg class="icon" aria-hidden="true">
              <use :href="'#icon-full_more_def'" fill="#fff"></use>
            </svg>
          </div>
        </div>
        <div class="showTitle">{{ postData.contents }}</div>
        <!-- 链接显示 -->
        <div class="lcoation" v-show="postData.linkurls[0].titletext">
          <svg class="icon" aria-hidden="true">
            <use fill="#2d6cfc" href="#icon-lianjie1"></use>
          </svg>
          <span>{{ postData.linkurls[0].titletext }}</span>
        </div>
        <!-- 预览图片 -->
        <div class="editorImgBox" v-if="postData.filecontentidArray.length > 0">
          <div class="inner">
            <div v-for="img in postData.filecontentidArray" :key="img">
              <div class="imgColumn" v-show="iconShow">
                <i class="el-icon-delete"></i><i class="el-icon-edit"></i>
              </div>
              <img
                class="editorImg"
                :style="`height:${
                  250 / postData.filecontentidArray.length
                }px;width:${300 / postData.filecontentidArray.length}px`"
                :src="imgSrc(img)"
              />
            </div>
          </div>
        </div>
        <div
          class="showIcon"
          :style="
            postData.socialtype === 'linkedIn'
              ? 'border-top: 2px solid #dedede'
              : ''
          "
        >
          <div
            class="svgIcon"
            v-for="(item, index) in svgIconList"
            :key="index"
          >
            <svg class="icon" aria-hidden="true">
              <use :href="`#icon-${item.icon}`"></use>
            </svg>
            <span class="svgMessage">{{ item.message }}</span>
          </div>
        </div>
      </el-card>
    </div>
    <div class="shareBox">
      <el-button class="btn" @click="swicthShow()">
        <!-- Edit post -->
        {{$t('vue_label_chatter_edit_post')}}
        </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    postData: {
      type: Object,
      default: Object,
    },
  },
  data() {
    return {
      svgIconList: [],
      token: this.$CCDK.CCToken.getToken(),
    };
  },
  created() {
    this.init();
  },
  methods: {
    showName(list){
      let str =''
      list.forEach(el => {
          if(el.checked && !str){
            str = el.socialmedianame
          }
      });
      return str
    },
    init() {
      
      if (this.postData.socialtype === "linkedIn") {
        this.svgIconList = [
          {
            icon: "dianzan2",
            message: "Like",
          },
          {
            icon: "pinglun1",
            message: "Comment  ",
          },
          {
            icon: "zhuanfa",
            message: "Share",
          },
          {
            icon: "fasong",
            message: "Send",
          },
        ];
      } else if (this.postData.socialtype === "twitter") {
        this.svgIconList = [
          {
            icon: "pinglun",
            message: "",
          },
          {
            icon: "zhuantui",
            message: "",
          },
          {
            icon: "dianzan",
            message: "",
          },
          {
            icon: "fenxiang1",
            message: "",
          },
          {
            icon: "fenxi",
            message: "",
          },
        ];
      } else if (this.postData.socialtype === "facebook") {
        this.svgIconList = [
          {
            icon: "dianzan2",
            message: "Like",
          },
          {
            icon: "pinglun1",
            message: "Comment  ",
          },
          {
            icon: "zhuanfa",
            message: "Share",
          },
        ];
      }
    },
    swicthShow() {
      this.$emit("swicthShow");
    },
    imgSrc(id) {
      return `${this.$cookies.get(
        "domainName"
      )}/chatterfile.action?m=previewSocial&fileid=${id}&binding=${this.token}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.lcoation {
  padding: 5px 5px 5px 10px;
  color: #2d6cfc;
  display: flex;
  width: fit-content;
  background: rgb(240, 240, 241);
  border-radius: 8px;
  align-content: center;
  align-items: center;
  margin: 5px 0px;
  .icon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    cursor: none;
  }
  span {
    margin-right: 5px;
  }
}
.editorImgBox {
  display: flex;
  justify-content: center;
  .inner {
    justify-content: space-around;
    display: flex;
    .imgColumn {
      display: flex;
      justify-content: space-around;
      i {
        cursor: pointer;
      }
    }
    .editorImg {
      margin: 10px 0px;
      height: 250px;
      width: 300px;
    }
    .editorImgPreview {
      margin: 10px 0px;
    }
    .editorImg:hover {
      box-shadow: 2px 2px 2px 2px #ccc;
    }
  }
}
.wrapper {
  background: #fff;
  .main {
    padding: 20px;
    width: 100%;
    .messageBox {
      min-height: 100px;
      margin-top: 10px;
      width: 100%;
    }
    .title {
      height: 21px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #333333;
      line-height: 21px;
    }
    .headBox {
      display: flex;
      justify-content: space-between;
      .logoBox {
        display: flex;
        justify-content: space-around;
        .logoImg {
          // height: 40px;
          // width: 40px;
          // margin: 0px 10px 0 0;
          .icon {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            margin: 0px 10px 0 0;
          }
        }
        .userBox {
          display: flex;
          flex-direction: column;
          .dateText {
            font-size: 12px;
            font-family: MicrosoftYaHei;
            color: #666666;
            line-height: 20px;
          }
        }
        .actionIcon polyline {
          stroke: red;
        }
      }
    }
    .showTitle {
      width: 100%;
      word-wrap: break-word;
      margin: 5px 0px;
    }
    .showIcon {
      display: flex;
      width: 100%;
      justify-content: space-around;
      padding: 5px 0px;
      cursor: pointer;
      .icon {
        width: 20px;
        height: 20px;
      }
      .svgMessage {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #999999;
        margin-left: 5px;
      }
    }
  }
  .shareBox {
    display: flex;
    margin-top: 20px;
    align-items: center;
    padding: 20px 0px;
    justify-content: end;
    border-top: 2px solid #dedede;
    padding: 20px;
    .btn {
      border: none;
      background: #2d6cfc;
      border-radius: 15px;
      color: #fff;
      font-weight: 700;
      margin-left: 10px;
    }
  }
}
</style>