<!--  -->
<template>
  <div class="insert">
    <el-drawer
      :title="$i18n.t('c808')"
      :append-to-body="true"
      :visible.sync="imgS"
      size="35%"
      :before-close="closeDrawer"
    >
      <div class="conent" v-loading="imgView">
        <div class="content-error">
          {{$t('c641')}}
        </div>
        <h1>{{$t('c642')}}</h1>
        <div class="imgs nfinite-list" v-infinite-scroll="handleScroll">
          <div
            v-for="(item, index) in imglist"
            :key="index"
            class="preview"
            :style="
              item.previewshow || item.checked
                ? 'border: 4px solid #2D6CFC;'
                : ''
            "
            @mouseenter="mouseover(index)"
            @mouseleave="mouseout(index)"
          >
            <!-- 图片 -->
            <el-image
              :src="imgSrc(item.filecontentid)"
              @click="upload(index)"
            ></el-image>
            <!-- 预览 -->
            <el-image-viewer
              v-if="showViewer"
              :on-close="
                () => {
                  showViewer = false;
                }
              "
              :url-list="preimg"
            />
            <!-- <button v-if="item.previewshow" @click="pre(index)">Preview</button> -->
          </div>
        </div>
      </div>
      <div class="uploadBt" v-loading="imgView">
        <el-upload
          class="upload-demo"
          action="/text"
          :show-file-list="false"
          :before-upload="logoBeforeAvatarUpload"
        >
          <el-button type="primary" size="small" class="bottomBt"
            >{{$t('label.tabpage.uploadsz')}}</el-button
          >
        </el-upload>
        <el-button type="primary" size="small" class="bottomBt" @click="sureImg"
          >{{$t('label.dataimport.type.insert')}}
        </el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import * as marketChannel from "./api";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  props: {
    img: {},
  },
  components: { ElImageViewer },
  data() {
    return {
      imglist: [], //图片列表数据
      token: this.$CCDK.CCToken.getToken(),
      preimg: [], //预览
      showViewer: false, //预览显示
      checkLength: 0, //选中图片数
      pageNum: 1, // 页数
      imgView: false,
      imgS: this.img,
    };
  },
  created() {
    this.getimglist();
  },
  methods: {
    handleScroll() {
      this.pageNum++;
      this.getimglist();
    },
    sureImg() {
      let imgArray = [];
      this.imglist.forEach((el) => {
        if (el.checked) {
          imgArray.push(el.filecontentid);
        }
      });
      let imgStr = imgArray.join(",");
      let obj = {
        imgArray: imgArray,
        imgStr: imgStr,
      };
      this.$emit("closeDrawer", false, obj);
    },
    //关闭弹框
    closeDrawer() {
      this.pageNum = 1;
      this.$emit("closeDrawer", false);
    },
    //滑入
    mouseover(index) {
      this.imglist[index].previewshow = true;
    },
    //滑出
    mouseout(index) {
      this.imglist[index].previewshow = false;
    },
    //预览图片
    
    pre(index) {
      this.preimg = [];
      let img = this.imgSrc(this.imglist[index].filecontentid);
      this.preimg.push(img);
      this.showViewer = true;
    },
    //获取图片列表
    getimglist() {
      this.imgView = true;
      let params = {
        orgid: this.$store.state.userInfoObj.orgId,
        pageNum: this.pageNum,
        pageSize: "18",
      };
      marketChannel.querySocialFileInfo(params).then((res) => {
        if (res.result) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].previewshow = false;
          }
          if (this.pageNum === 1) {
            this.imglist = res.data;
          } else {
            this.imglist = [...this.imglist, ...res.data];
          }
          this.imgView = false;
        }
      });
    },
    //选中图片
    upload(index) {
      if (this.imglist[index].checked) {
        this.imglist[index].checked = false;
        this.checkLength--;
      } else if (this.checkLength < 4) {
        this.imglist[index].checked = true;
        this.checkLength++;
      }
      // this.$emit('closeDrawer', false, this.imglist[index].filecontentid)
    },
    //上传图片
    logoBeforeAvatarUpload(file) {
      let fileSize = file.size;
      if (fileSize < 200 * 1024) {
        this.pageNum = 1;
        let formData = new FormData();
        formData.append("file", file);
        formData.append("orgid", this.$store.state.userInfoObj.orgId);

        this.imgView = true;
        marketChannel
          .socialUploadFile(formData)
          .then((res) => {
            this.$message.success(res.data.uploadMessage);
            this.getimglist();
          })
          .catch(() => {
            return false;
          });
      } else {
        this.$message.error("上传失败，文件不能超过200k");
      }
    },
    //通过id获取图片
    imgSrc(id) {
      return `${this.$cookies.get(
        "domainName"
      )}/chatterfile.action?m=previewSocial&fileid=${id}&binding=${this.token}`;
    },
  },
};
</script>

<style lang='scss' scoped >
.uploadBt {
  height: 50px;
  padding-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: left;
  width: 100%;
  position: sticky;
  bottom: 0px;
  background: #f7f7f7;
  align-items: center;
  .bottomBt {
    width: 65px;
    height: 32px;
    margin-left: 5px;
  }
}
::v-deep .el-drawer__header {
  border-bottom: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 0;
}
::v-deep .el-drawer__header > :first-child {
  color: #333;
  font-size: 18px;
  font-weight: 600;
}
.conent {
  width: 95%;
  margin-left: 10px;
  .content-error {
    color: #2d6cfc;
    padding-top: 20px;
  }
  h1 {
    color: rgb(20, 19, 19);
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
  }
  .imgs {
    overflow: auto;
    height: 500px;
    .preview {
      width: 30%;
      height: 80px;
      position: relative;
      margin-right: 10px;
      margin-bottom: 20px;
      overflow: hidden;
      float: left;
      background: rgb(157, 219, 209);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    button {
      position: absolute;
      bottom: 0;
      left: 3px;
      outline: none;
      border: none;
      color: #fff;
      border-radius: 3px;
      background: #333;
      opacity: 0.8;
      zoom: 0.8;
      z-index: 999;
      cursor: pointer;
    }
  }
}
</style>
