<!--  -->
<template>
  <div class="">
    <el-drawer
      :title="$i18n.t(mainTitle)"
      :visible.sync="drawer"
      :before-close="handleClose"
      :wrapperClosable="false"
      size="845px"
    >
      <div class="content" v-loading="socialLoad">
        <!-- 编辑区 -->
        <div
          class="postForm"
          v-for="(item, index) in formList"
          :key="index"
          :style="
            pathType !== 'draft' && pathType !== 'edit'
              ? 'border-left: 1px solid #dedcda'
              : ''
          "
        >
          <div
            class="aside"
            @mouseenter="asideImgFlgEnter(index)"
            @mouseleave="asideImgFlgLeave(index)"
          >
            <svg
              class="icon asideImg"
              aria-hidden="true"
              :disabled="pathType === 'edit'"
              @click="detelePsot(index, formList[index].asideImgFlg)"
              :style="
                pathType === 'edit' || pathType === 'draft'
                  ? 'cursor: no-drop'
                  : 'cursor: pointer'
              "
            >
              <use
                :href="
                  formList[index].asideImgFlg
                    ? srcImg(item.socialtype)
                    : `#icon-delete-round`
                "
              ></use>
            </svg>
          </div>
          <div
            class="errMessage"
            v-show="
              (errMessage[index][0] ||
                errMessage[index][1] ||
                errMessage[index][2] ||
                errMessage[index][3]) &&
              onceEdit
            "
          >
            <el-tooltip class="item" effect="dark" placement="top-start">
              <div slot="content">
                <div class="errLine">{{ errMessage[index][0] }}</div>
                <div class="errLine">{{ errMessage[index][1] }}</div>
                <div class="errLine">{{ errMessage[index][2] }}</div>
                <div class="errLine">{{ errMessage[index][3] }}</div>
              </div>
              <svg class="icon errSvg" aria-hidden="true">
                <use href="#icon-warning_round"></use>
              </svg>
            </el-tooltip>
          </div>
          <div class="post" v-show="item.editFlg">
            <div class="dropLeft">
              <span style="width: 30%">
                <!-- 发帖自 -->
                {{ $t("c655") }}:</span
              >
              <el-dropdown
                @command="handleCommand"
                trigger="click"
                placement="bottom-start"
                :disabled="pathType === 'edit' || pathType === 'draft'"
              >
                <span
                  class="el-dropdown-link cursor-pointer"
                  @click="lockItem(index)"
                >
                  <span
                    :class="
                      pathType === 'edit' || pathType === 'draft'
                        ? ''
                        : 'line_title'
                    "
                    >&ensp;{{ fromName(index, item) }}</span
                  >
                  <i class="el-icon-caret-bottom"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <div
                    class="postingCheck"
                    v-for="(ite, checkindex) in item.checkedList"
                    :key="checkindex"
                  >
                    <!-- 显示当前社交平台下的用户 -->
                    <el-checkbox
                      v-model="ite.checked"
                      v-if="item.socialtype !== 'twitter'"
                    >
                      <img
                        :src="`data:image/png;base64,${ite.socialmediapicture}`"
                        v-if="ite.socialmediapicture"
                        class="mainImg"
                      />
                      <svg aria-hidden="true" v-else class="mainImg">
                        <use href="#icon-contacts_def"></use>
                      </svg>
                      <span style="padding-right: 20px">{{
                        ite.socialmedianame
                      }}</span></el-checkbox
                    >
                    <!-- twitter 只支持单选 -->
                    <div
                      class="twitterRadio"
                      v-if="item.socialtype === 'twitter'"
                    >
                      <el-radio
                        v-model="item.radio"
                        :label="checkindex"
                        @change="changeRadio(index, checkindex)"
                      >
                        <img
                          :src="`data:image/png;base64,${ite.socialmediapicture}`"
                          v-if="ite.socialmediapicture"
                          class="mainImg"
                        />
                        <svg aria-hidden="true" v-else class="mainImg">
                          <use href="#icon-contacts_def"></use>
                        </svg>

                        {{ ite.socialmedianame }}</el-radio
                      >
                    </div>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="dropRight">
              <span style="width: 20%"> {{ $t("c806") }}:</span>
              <el-popover
                placement="bottom"
                width="700"
                trigger="click"
                :ref="`popover-${index}`"
                class="cursor-pointer"
              >
                <p class="dropTitle">
                  <!-- Publishing Options -->
                  {{ $t("c638") }}
                </p>
                <div class="dropWhen">
                  <el-radio v-model="item.timeFlg" :label="true">
                    <!-- Posting now -->
                    {{ $t("c807") }}
                  </el-radio>
                  <el-radio v-model="item.timeFlg" :label="false">
                    <!-- Schedule Post -->
                    {{ $t("c640") }}
                  </el-radio>
                </div>
                <div class="customTime" v-if="item.timeFlg === false">
                  <div class="dateView">
                    <span>
                      <!-- Date -->
                      {{ $t("D") }}
                    </span>
                    <el-date-picker
                      v-model="item.dateValue"
                      size="mini"
                      style="width: 180px"
                      type="date"
                      value-format="yyyy-MM-dd"
                      :picker-options="pickerOptions"
                      :clearable="false"
                    >
                    </el-date-picker>
                  </div>
                  <div class="selectDate">
                    <span>
                      <!-- Time -->
                      {{ $t("label.emailsync.status.time") }}
                    </span>
                    <div class="inputTime">
                      <el-time-select
                        size="mini"
                        style="width: 180px"
                        v-model="timeSelect"
                        :picker-options="{
                          start: newHour(item.dateValue),
                          step: '00:15',
                          end: '23:45',
                        }"
                        :format="'HH:mm'"
                        :clearable="false"
                      >
                      </el-time-select>
                    </div>
                    <div class="selectBt"></div>
                  </div>
                </div>
                <div class="dropBtBox">
                  <el-button
                    type="primary"
                    :size="small"
                    @click="applyWhen(index)"
                  >
                    <!-- Apply -->
                    {{ $t("label.apply") }}
                  </el-button>
                  <el-button :size="small" @click="closeMessageDrop(index)">
                    <!-- Cancel -->
                    {{ $t("label.tabpage.cancel") }}
                  </el-button>
                </div>
                <span
                  slot="reference"
                  class="el-dropdown-link"
                  @click="lockItem(index)"
                >
                  <span class="line_title" v-if="item.sendType">
                    &ensp;&ensp;
                    <!-- Posting Now -->
                    {{ $t("c807") }}
                  </span>
                  <span class="line_title" v-else>
                    &ensp;&ensp;{{ item.dateValue }}&emsp;{{ item.timeValue }}
                  </span>
                  <i class="el-icon-caret-bottom" @click="lockItem(index)"></i>
                </span>
              </el-popover>
            </div>
            <div class="eidterBox">
              <chat-input
                :isUseTime="false"
                :isUseLoc="false"
                :isUseVideo="false"
                :isUseUploadApi="false"
                :isUseLink="false"
                v-model="item.contents"
                :placeholder="$i18n.t('c634')"
                :autosize="{ minRows: 6, maxRows: 6 }"
                @linkChange="linkUpload(arguments[0], index)"
                @clearBtnDialog="clearBtnDialog(index)"
                @uploadImageOther="imgPopup(index)"
                @send="changeEditFlg(arguments[0], index)"
                @input="onceEditClick"
                :seedText="$i18n.t('label.preview_post')"
                class="inputClass"
                :index="index"
                :maxlength="item.socialtype == 'twitter' ? 280 : ''"
                show-word-limit
              >
                <!-- <template v-slot:socialLink>
                  <div class="lcoation" v-show="item.linkurls[0].titletext">
                    <svg class="icon" aria-hidden="true">
                      <use fill="#2d6cfc" href="#icon-lianjie1"></use>
                    </svg>
                    <span>{{ item.linkurls[0].titletext }}</span
                    ><span class="close" @click="clearLinks(index)">
                      <svg aria-hidden="true">
                        <use href="#icon-chahao"></use>
                      </svg>
                    </span>
                  </div>
                </template> -->
                <!-- form表单 -->
                <template v-slot:btn>
                  <div class="icon-margin">
                    <svg
                      class="icon"
                      aria-hidden="true"
                      @click="close(), (item.formsPopFlg = true)"
                    >
                      <use href="#icon-liebiao"></use>
                    </svg>
                    <div class="formPop" v-show="item.formsPopFlg">
                      <i class="linksClose el-icon-close" @click="close"></i>
                      <p class="formPopTitle">
                        <!-- Choose a form -->
                        {{ $t("c864") }}
                      </p>
                      <el-select
                        v-model="posterFormSelect"
                        size="mini"
                        filterable
                      >
                        <el-option
                          v-for="item in posterFormList"
                          :key="item.id"
                          :label="item.subject"
                          :value="item.id"
                          style="max-width: 500px"
                        >
                        </el-option>
                      </el-select>
                      <div class="formPopBt">
                        <el-button
                          type="primary"
                          size="mini"
                          @click="formSelect(index)"
                        >
                          <!-- Insert -->
                          {{ $t("label.dataimport.type.insert") }}
                        </el-button>
                        <el-button
                          @click="item.formsPopFlg = false"
                          size="mini"
                        >
                          <!-- Cancel -->
                          {{ $t("label.emailtocloudcc.button.back") }}
                        </el-button>
                      </div>
                    </div>
                  </div>
                </template>
                <!-- social link -->
                <!-- <template v-slot:linkBox>
                  <div class="icon-margin">
                    <svg
                      class="icon"
                      aria-hidden="true"
                      @click="close(), (item.linksPopFlg = true)"
                    >
                      <use href="#icon-lianjie1"></use>
                    </svg>
                    <div class="linksPop" v-show="item.linksPopFlg">
                      <i class="linksClose el-icon-close" @click="close"></i>
                      <p class="formPopTitle">Add links</p>
                      <span class="text-center">Title：</span>
                      <el-input
                        v-model="linksTitle"
                        size="mini"
                        class="linksInput"
                      ></el-input>
                      <span class="text-center">URL：</span>
                      <el-input
                        v-model="linksUrl"
                        size="mini"
                        class="linksInput"
                      ></el-input>
                      <el-button
                        class="linkAction"
                        type="primary"
                        size="mini"
                        @click="linksAction(index)"
                      >
                        Insert
                      </el-button>
                    </div>
                  </div>
                </template> -->
                <!-- 图片预览 -->
                <template v-slot:content>
                  <div
                    class="editorImgContent"
                    v-if="item.filecontentidArray.length > 0"
                    @mouseover="iconShow = true"
                    @mouseout="iconShow = false"
                  >
                    <div class="inner">
                      <div v-for="img in item.filecontentidArray" :key="img">
                        <div class="imgColumn" v-show="iconShow">
                          <i
                            class="el-icon-delete"
                            @click="deleteImg(index)"
                          ></i
                          ><i class="el-icon-edit" @click="editImg(index)"></i>
                        </div>
                        <img
                          class="editorImg"
                          :style="`height:${
                            250 / item.filecontentidArray.length
                          }px;width:${300 / item.filecontentidArray.length}px`"
                          :src="imgSrc(img)"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </chat-input>
            </div>
          </div>
          <div class="showPost" v-show="!item.editFlg">
            <previewSocial
              :postData="item"
              @swicthShow="changeEditFlg('', index)"
            ></previewSocial>
          </div>
        </div>
        <!-- 添加post区  -->
        <div
          class="addPost"
          v-show="pathType !== 'draft' && pathType !== 'edit'"
        >
          <div class="addImg">
            <i class="el-icon-plus"></i>
          </div>
          <div class="addMes">{{ $t("c635") }}:</div>
          <svg
            class="img"
            aria-hidden="true"
            @click="addNextPost('facebook', 'create')"
            v-show="facebookList.length !== 0"
          >
            <use href="#icon-facebook_white"></use>
          </svg>
          <svg
            class="img"
            aria-hidden="true"
            @click="linkAcount('facebook')"
            v-show="facebookList.length === 0"
          >
            <use href="#icon-facebook"></use>
          </svg>
          <svg
            class="img"
            aria-hidden="true"
            @click="addNextPost('twitter', 'create')"
            v-show="twitterList.length !== 0"
          >
            <use href="#icon-twitter_white"></use>
          </svg>
          <svg
            class="img"
            aria-hidden="true"
            @click="linkAcount('twitter')"
            v-show="twitterList.length === 0"
          >
            <use href="#icon-twitter"></use>
          </svg>
          <svg
            class="img"
            aria-hidden="true"
            @click="addNextPost('linkedIn', 'create')"
            v-show="linkedInList.length !== 0"
          >
            <use href="#icon-linkedIn_white"></use>
          </svg>
          <svg
            class="img"
            aria-hidden="true"
            @click="linkAcount('linkedIn')"
            v-show="linkedInList.length === 0"
          >
            <use href="#icon-Linkedin"></use>
          </svg>
        </div>
        <!-- 选择公司区 -->
        <div class="campagin" v-show="pathType !== 'draft'">
          <div class="mes">
            <!-- Campaign -->
            {{ $t("vue_label_normal_campaign_sf") }}
            <el-tooltip
              class="item"
              effect="dark"
              :content="$i18n.t('c894')"
              placement="right"
            >
              <svg class="icon" aria-hidden="true">
                <use href="#icon-prompt"></use>
              </svg>
            </el-tooltip>
          </div>
          <div class="camSelect">
            <el-select
              v-model="campainSelect"
              class="select"
              filterable
              :clearable="true"
            >
              <el-option
                v-for="item in campaignList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                style="max-width: 500px"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="bottomBox">
        <div
          class="availableImg"
          v-show="this.onceEdit && this.errMessageAccout() !== 0"
          :style="pathType === 'draft' ? 'left: 33%;' : 'left: 20%'"
        >
          {{ this.errMessageAccout() }}
        </div>
        <el-button
          class="postBt"
          @click="saveDrafts('send')"
          type="primary"
          v-loading="saveLoad"
          :disabled="this.errMessageAccout() !== 0"
        >
          {{ $t(btTile) }}（{{ this.successMessageAccout() }}）
        </el-button>
        <el-button
          class="postBt ml-10"
          @click="saveDrafts('drafts')"
          v-loading="saveLoad"
          :disabled="this.errMessageAccout() !== 0"
          v-show="pathType !== 'draft'"
        >
          <!-- Save as Draft -->
          {{ $t("c637") }}
        </el-button>
      </div>
    </el-drawer>
    <!-- 插入图片弹窗 -->
    <insert :img="img" @closeDrawer="closeDrawer"></insert>
    <!-- 关闭提示 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :title="$i18n.t('c826')"
      :show-close="false"
      top="15%"
      width="600px"
      :close-on-click-modal="false"
    >
      <span style="font-size: 16px; font-weight: bold; word-break: break-word">
        <!-- 确认关闭 -->
        <!-- You have unsaved changes on this page that will be lost if you continue. -->
        {{ $t("c827") }}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="discard">
          <!-- changes -->
          <!-- Yes, discard changes -->
          {{ $i18n.t("label.discard_change") }}
        </el-button>
        <el-button
          @click="saveDrafts('drafts')"
          size="mini"
          v-loading="saveLoad"
          :disabled="this.errMessageAccout() !== 0"
        >
          <!-- drafts -->
          <!-- Save as drafts -->
          {{ $i18n.t("c637") }}
        </el-button>
        <el-button size="mini" @click="continueEdit">
          <!-- Continue editing -->
          {{ $i18n.t("label.continue_editing") }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 发帖提示 -->
    <div class="messageBox" v-if="messageBoxFlg">
      <i @click="closeMessage" class="el-icon-close"></i>
      <b>{{
        messageList.length === 1 ? messageList[0].title : messageList[1].title
      }}</b>
      <div v-if="messageList && messageList[0]">{{ messageList[0].data }}</div>
      <div v-if="messageList[1] && messageList[1].data.length > 0">
        <div v-for="(item, index) in messageList[1].data" :key="index">
          <p style="margin: 10px">{{ item }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as marketChannel from "./api";
import ChatInput from "@/components/ChatInput/index";
import insert from "@/views/social/components/insertimg.vue";
import previewSocial from "@/views/social/components/previewSocial.vue";
import debounce from "lodash.debounce";
export default {
  components: { ChatInput, insert, previewSocial },
  created() {
    this.onceEdit = false;
    this.getLookupData();
    this.init();
    // 获取当前日期时间
    let dd = new Date();
    let y = dd.getFullYear();
    let m =
      dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
    let d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
    this.fromViewDate = y + "-" + m + "-" + d;
  },
  computed: {
    userId() {
      return this.$store.state.userInfoObj.userId;
    },
  },
  watch: {
    formList: {
      handler(nval) {
        let index = 0;
        nval.forEach((el) => {
          let userNone = false;
          el.checkedList.forEach((checkItem) => {
            if (checkItem.checked) {
              userNone = true;
            }
          });
          let errM = [];
          if (!userNone) {
            errM[0] = this.$i18n.t("c615"); //"No account has been selected";
          } else {
            errM[0] = "";
          }
          if (!el.contents) {
            errM[1] = this.$i18n.t("c617"); //"Text is required to publish a post";
          } else {
            errM[1] = "";
          }
          if (el.socialtype === "twitter") {
            let twIndex = 0;
            let twitterFlg = false;
            this.formList.forEach((twitterIndex) => {
              if (
                el.socialmediaid === twitterIndex.socialmediaid &&
                twIndex !== index &&
                twitterIndex.socialtype === "twitter" &&
                el.contents === twitterIndex.contents
              ) {
                let dateOne = el.dateValue + " " + el.timeValue + ":00";
                let dateTwo = "";
                if (el.timeValue) {
                  dateOne = el.dateValue + " " + el.timeValue + ":00";
                } else {
                  dateOne = el.dateValue + " " + "00:00";
                }
                if (twitterIndex.timeValue) {
                  dateTwo =
                    twitterIndex.dateValue +
                    " " +
                    twitterIndex.timeValue +
                    ":00";
                } else {
                  dateTwo = twitterIndex.dateValue + " " + "00:00";
                }
                let date1 = el.sendType ? new Date() : new Date(dateOne);
                let date2 = twitterIndex.sendType
                  ? new Date()
                  : new Date(dateTwo);
                let date3 = date2.getTime() - new Date(date1).getTime();
                let leave1 = Math.abs(date3) % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
                let hours = Math.floor(leave1 / (3600 * 1000));
                let days = Math.floor(Math.abs(date3) / (24 * 3600 * 1000));
                let diff = 0;
                diff = Math.abs(days) * 24 + Math.abs(hours);
                if (Math.abs(diff) < 24) {
                  twitterFlg = true;
                }
              }
              twIndex++;
            });
            if (twitterFlg) {
              errM[2] =
                // "Change the content or publish time for this post. Twitter doesn't allow more than one post with the same content to be published within 24 hours.";
                this.$i18n.t("c618");
            } else {
              errM[2] = "";
            }
            if (el.contents.length > 280) {
              errM[3] = this.$i18n.t("c619"); //"Twitter posts must have 280 characters or less. Try using contractions to shorten things up";
            } else {
              errM[3] = "";
            }
          }
          this.errMessage[index] = errM;
          index++;
        });
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      saveLoad: false, //保存时loading
      socialLoad: false, // 发帖页loading
      onceEdit: false,
      errMessage: [],
      messageList: [
        {
          data: "",
          type: "",
          title: "",
        },
      ],
      messageBoxFlg: false,
      btTile: "c807", //通过并预约帖子
      posterFormSelect: "",
      posterFormList: [], //表单数据列表

      linksUrl: "http://",

      linksTitle: "",

      posterId: "",
      mainTitle: "c633", //通过并预约帖子
      campaignList: [],
      userData: [],
      lockIndex: 0, //当前操作项
      formList: [],
      campainSelect: "",
      dialogVisible: false,
      showBtList: [],
      fromViewDate: "",
      timeSelect: this.newHour(),
      faceImg: "#icon-facebook_white",
      twitterImg: "#icon-twitter_white",
      inImg: "#icon-linkedIn_white",
      drawer: false,
      img: false,
      token: this.$CCDK.CCToken.getToken(),
      iconShow: false,
      // 删除图片
      linkedInList: [],
      facebookList: [],
      twitterList: [],
      pickerOptions: {
        disabledDate(time) {
          let nowData = new Date();
          nowData = new Date(nowData.setDate(nowData.getDate() - 1));
          return time < nowData;
        },
      },
      pathType: "", //当前打开途径
    };
  },
  methods: {
    //时间选择限制
    newHour(date) {
      if (this.fromViewDate === date) {
        let newDate = new Date();
        let hour = newDate.getHours();
        let min = newDate.getMinutes();
        if (min > 45) {
          hour++;
          min = "00";
        } else if (30 < min < 45) {
          min = "45";
        } else if (15 < min < 30) {
          min = "30";
        } else if (0 < min < 15) {
          min = "15";
        }
        return `${hour}:${min}`;
      } else {
        return `00:00`;
      }
    },
    // openPath 打开的途径。  row 数据
    // clone 克隆 批量克隆状态  edit 编辑  create  新建 重新发送   draft 批准草稿 批量批准草稿

    async openPost(openPath, row) {
      this.drawer = true;
      this.socialLoad = true;
      this.close();
      this.init();
      let res = await marketChannel.getScoialUserinfo({
        orgid: this.$store.state.userInfoObj.orgId,
      });
      if (res.result) {
        res.data.list.forEach((element) => {
          element.checked = false;
        });
        this.userData = res.data.list;
        this.socialLoad = false;
        this.userData.forEach((item) => {
          if (item.socialtype === "linkedIn") {
            this.linkedInList.push(item);
          } else if (item.socialtype === "facebook") {
            this.facebookList.push(item);
          } else if (item.socialtype === "twitter") {
            this.twitterList.push(item);
          }
        });
        if (this.linkedInList.length > 0) {
          this.linkedInList[0].checked = true;
        }
        if (this.facebookList.length > 0) {
          this.facebookList[0].checked = true;
        }
        if (this.twitterList.length > 0) {
          this.twitterList[0].checked = true;
        }
        switch (openPath) {
          case "clone":
            this.pathType = "clone";
            this.btTile = "c807"; //Posting Now
            this.mainTitle = "c879"; //复制社交帖子
            this.onceEdit = true;
            this.openClone(row);
            break;
          case "edit":
            this.pathType = "edit";
            this.btTile = "c807";
            this.posterId = row.id;
            this.mainTitle = "c880"; //编辑社交帖子
            this.openEdit(row);
            break;
          case "create":
            this.pathType = "create";
            this.btTile = "c807";
            this.mainTitle = "c633"; //New Social Posts
            this.openCreate(row);
            break;
          case "draft":
            this.pathType = "draft";
            this.mainTitle = "c975"; // "Approve and schedule drafts";
            this.btTile = "c976"; //"Approve and schedule posts";
            this.openDraft(row);
            break;
          case "reschedule":
            this.pathType = "reschedule";
            this.mainTitle = "c633"; //"New Social Posts";
            this.openClone(row);
            break;
          default:
            break;
        }
      } else {
        this.$message({
          showClose: true,
          type: "error",
          message: res.returnInfo,
        });
      }
    },
    // 编辑打开

    openEdit(row) {
      // 获取编辑信息
      this.socialLoad = true;
      marketChannel
        .getPostInfoDetail({
          orgid: this.$store.state.userInfoObj.orgId,
          postsid: row.id,
        })
        .then((res) => {
          if (res.result) {
            this.socialLoad = false;
            this.init();
            this.userData.forEach((el) => {
              if (el.socialmediaid === res.data.postdata.socialmediaid) {
                el.checked = true;
              }
            });
            this.campainSelect = res.data.campaignid;
            this.formList.push({
              selecttime: this.fromViewDate,
              sendType: true,
              asideImgFlg: true,
              postsid: row.id,
              filecontentid: res.data.postdata.filecontentids,
              filecontentidArray: this.imgListSpilt(
                res.data.postdata.filecontentids
              ),
              socialtype: res.data.postdata.socialtype,
              editFlg: true,
              timeFlg: true,
              contents: res.data.postdata.textcontent,
              content: res.data.postdata.textcontent,
              dateValue: row.timingdate ? row.timingdate.substring(0, 11) : "",
              timeValue: row.timingdate ? row.timingdate.substring(11, 16) : "",
              checkedList: this.filterSocialUser(res.data.postdata.socialtype),
              posttype: res.data.postdata.poststype,
              searchPost: "",
              linkurls: res.data.postdata.linkurls,
              linksPopFlg: false,
              formsPopFlg: false,
              radio: 0,
            });
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
      // 列表操作eidt
    },
    // 批准预约打开

    openDraft(row) {
      let newDate = new Date();
      let hour = newDate.getHours();
      let min = newDate.getMinutes();
      this.userData.forEach((el) => {
        if (el.socialmediaid === row.socialmediaid) {
          el.checked = true;
        }
      });
      if (Array.isArray(row)) {
        row.forEach((row) => {
          this.formList.push({
            selecttime: this.fromViewDate,
            sendType: true,
            asideImgFlg: true,
            postsid: row.id,
            filecontentid: row.filecontentids,
            filecontentidArray: this.imgListSpilt(row.filecontentids),
            socialtype: row.socialtype,
            editFlg: true,
            timeFlg: true,
            contents: row.textcontent,
            content: row.textcontent,
            dateValue: this.fromViewDate,
            timeValue: `${hour}:${min}`,
            checkedList: this.filterSocialUser(row.socialtype),
            posttype: row.poststype,
            searchPost: "",
            linkurls: [
              {
                originalurl: "",
                titletext: "",
              },
            ],
            linksPopFlg: false,
            formsPopFlg: false,
            radio: 0,
          });
        });
      } else {
        this.formList.push({
          selecttime: this.fromViewDate,
          sendType: true,
          asideImgFlg: true,
          postsid: row.id,
          filecontentid: row.filecontentids,
          filecontentidArray: this.imgListSpilt(row.filecontentids),
          socialtype: row.socialtype,
          editFlg: true,
          timeFlg: true,
          contents: row.textcontent,
          content: row.textcontent,
          // 判断是否是克隆 和失败克隆 普通克隆通过poststype 失败克隆通过有无时间决定发送条件
          dateValue: this.fromViewDate,
          timeValue: `${hour}:${min}`,
          checkedList: this.filterSocialUser(row.socialtype),
          posttype: row.poststype,
          searchPost: "",
          linkurls: row.linkurls,
          linksPopFlg: false,
          formsPopFlg: false,
          radio: 0,
        });
      }
    },
    // 克隆打开 失败重新发送打开

    openClone(row) {
      this.userData.forEach((el) => {
        if (el.socialmediaid === row.socialmediaid) {
          el.checked = true;
        }
      });
      if (Array.isArray(row)) {
        row.forEach((row) => {
          this.formList.push({
            selecttime: this.fromViewDate,
            sendType: true,
            asideImgFlg: true,
            postsid: row.id,
            filecontentid: row.filecontentids,
            filecontentidArray: this.imgListSpilt(row.filecontentids),
            socialtype: row.socialtype,
            editFlg: true,
            timeFlg: true,
            contents: row.textcontent,
            content: row.textcontent,
            dateValue: row.timingdate ? row.timingdate.substring(0, 11) : "",
            timeValue: row.timingdate ? row.timingdate.substring(11, 16) : "",
            checkedList: this.filterSocialUser(row.socialtype),
            posttype: row.poststype,
            searchPost: "",
            linkurls: [
              {
                originalurl: "",
                titletext: "",
              },
            ],
            linksPopFlg: false,
            formsPopFlg: false,
            radio: 0,
          });
        });
      } else {
        this.formList.push({
          selecttime: this.fromViewDate,
          sendType: true,
          asideImgFlg: true,
          postsid: row.id,
          filecontentid: row.filecontentids,
          filecontentidArray: this.imgListSpilt(row.filecontentids),
          socialtype: row.socialtype,
          editFlg: true,
          timeFlg: true,
          contents: row.textcontent,
          content: row.textcontent,
          // 判断是否是克隆 和失败克隆 普通克隆通过poststype 失败克隆通过有无时间决定发送条件
          dateValue: row.timingdate ? row.timingdate.substring(0, 11) : "",
          timeValue: row.timingdate ? row.timingdate.substring(11, 16) : "",
          checkedList: this.filterSocialUser(row.socialtype),
          posttype: row.poststype,
          searchPost: "",
          linkurls: row.linkurls,
          linksPopFlg: false,
          formsPopFlg: false,
          radio: 0,
        });
      }
    },
    // 新建打开

    openCreate(row) {
      this.formList.push({
        sendType: true,
        asideImgFlg: true,
        socialtype: row.socialType,
        editFlg: true,
        timeFlg: true,
        contents: "",
        content: "",
        dateValue: this.fromViewDate,
        selecttime: this.fromViewDate,
        filecontentidArray: [],
        timeValue: "",
        checkedList: this.filterSocialUser(row.socialType),
        searchPost: "",
        linkurls: [
          {
            originalurl: "",
            titletext: "",
          },
        ],
        linksPopFlg: false,
        formsPopFlg: false,
        radio: 0,
      });
    },
    // 文本第一次操作后
    onceEditClick() {
      this.onceEdit = true;
    },
    successMessageAccout() {
      let err = 0;
      this.errMessage.forEach((el) => {
        if (el[0] || el[1] || el[2] || el[3]) {
          err++;
        }
      });
      return this.formList.length - err;
    },
    errMessageAccout() {
      let err = 0;
      this.errMessage.forEach((el) => {
        if (el[0] || el[1] || el[2] || el[3]) {
          err++;
        }
      });
      return err;
    },
    changeRadio(index, checkIndex) {
      this.formList[index].checkedList.forEach((el) => {
        el.checked = false;
      });
      this.formList[index].checkedList[checkIndex].checked = true;
    },
    closeMessage() {
      this.messageBoxFlg = false;
    },
    close() {
      this.formList.forEach((el) => {
        el.linksPopFlg = false;
        el.formsPopFlg = false;
      });
    },
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    setBtTitle() {
      if (this.pathType !== "draft") {
        let titleFlg = false;
        this.formList.forEach((el) => {
          if (!el.timeFlg) {
            titleFlg = true;
          }
        });
        if (titleFlg) {
          this.btTile = "c640";
        } else {
          this.btTile = "c807";
        }
      }
    },
    applyWhen(index) {
      if (this.formList[index].dateValue == null) {
        this.formList[index].dateValue = this.getNowFormatDate();
      }
      if (!this.formList[index].timeFlg) {
        this.formList[index].sendType = false;
        this.fillTime(index);
      } else {
        this.formList[index].sendType = true;
        this.publishNow(index);
      }
      this.setBtTitle();
      this.closeMessageDrop(index);
    },
    //插入form链接
    formSelect(index) {
      if (this.posterFormSelect) {
        marketChannel
          .getShareInfo({ id: this.posterFormSelect })
          .then((res) => {
            if (res.result && res.data) {
              this.$nextTick(() => {
                this.$bus.$emit(`addContentToChat${index}`, " " + res.data.url);
                this.onceEditClick();
              });
            } else {
              this.$message({
                showClose: true,
                type: "error",
                message: res.returnInfo,
              });
            }
          });
        this.formList[index].formsPopFlg = false;
        this.posterFormSelect = "";
      } else {
        this.$message.error(this.$i18n.t("label.select.options"));
      }
    },
    // 获取查找数据
    getLookupData() {
      marketChannel
        .getFormList({
          // orgid: this.$store.state.userInfoObj.orgId,
          // searchKeyWord: "",
          sortField: "createdate",
          sortDir: "desc",
          // pageNum: 1,
          // pageSize: 1000,
        })
        .then((res) => {
          if (res.result) {
            this.posterFormList = res.data.formlist;
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
    },
    linkAcount(type) {
      this.$emit("linkAcount", type);
    },

    gotoDate(date, index) {
      this.formList[index].dateValue = date;
    },
    asideImgFlgEnter(index) {
      this.formList[index].asideImgFlg = false;
    },
    asideImgFlgLeave(index) {
      this.formList[index].asideImgFlg = true;
    },
    //获取图片
    imgSrc(id) {
      return `${this.$cookies.get(
        "domainName"
      )}/chatterfile.action?m=previewSocial&fileid=${id}&binding=${this.token}`;
    },
    //禁止选择今天之前的日期

    disabledDate(current) {
      return current < this.$moment().subtract(1, "day"); //今天之前的年月日不可选，不包括今天
    },
    imgPopup(index) {
      this.lockIndex = index;
      this.img = true;
    },
    // 插入图片
    closeDrawer(show, val) {
      this.img = show;
      if (val) {
        this.formList[this.lockIndex].filecontentid = val.imgStr;
        this.formList[this.lockIndex].filecontentidArray = val.imgArray;
        this.onceEditClick();
      }
    },

    changeLink(value, index) {
      this.linksTitle = value;
      this.formList[index].linkurls[0].titletext = value;
      this.onceEditClick();
    },
    //清除链接

    clearLinks(index) {
      this.formList[index].linkurls = [
        {
          originalurl: "",
          titletext: "",
        },
      ];
      this.linksUrl = "http://";
      this.linksTitle = "";
    },
    // 插入链接操作

    linksAction(index) {
      let res =
        /^(((ht|f)tps?):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/.test(
          this.linksUrl
        );
      if (!res) {
        this.$message.error(this.$i18n.t("label.input.right.url"));
      } else {
        let linksObj = {
          originalurl: " " + this.linksUrl,
          titletext: this.linksTitle,
        };
        this.formList[index].linkurls[0] = linksObj;
        this.formList[index].linksPopFlg = false;
      }
    },

    formPopup(index) {
      this.formList[index].formsPopFlg = !this.formList[index].formsPopFlg;
      this.lockIndex = index;
      this.formList[index].linksPopFlg = false;
    },

    linksPopup(index) {
      this.formList[index].linksPopFlg = !this.formList[index].linksPopFlg;
      this.formList[index].formsPopFlg = false;
      this.lockIndex = index;
    },
    // 删除图片数据
    deleteImg(index) {
      // 移除文件id
      this.formList[index].filecontentid = "";
      this.formList[index].filecontentidArray = [];
    },
    editImg() {
      this.img = true;
    },
    // 保存图片

    saveImg(file, index) {
      this.formList[index].file = file;
    },

    searchMethod(text, item) {
      let userList = [];
      if (text === "") {
        this.formList[this.lockIndex].checkedList = this.filterSocialUser(
          item.socialtype
        );
      } else if (item) {
        item.forEach((el) => {
          if (el.socialmedianame.indexOf(text) !== -1) {
            userList.push(el);
          }
        });
        this.formList[this.lockIndex].checkedList = userList;
      }
    },
    refreshList() {
      this.$emit("refreshList");
    },
    init() {
      this.getUserinfo();
      this.getMarketing();
    },

    imgListSpilt(fileid) {
      if (fileid) {
        let idArray = fileid.split(",");
        return idArray;
      } else {
        return [];
      }
    },
    // 获取发帖用户信息
    getUserinfo() {
      // 根据默认值 设定显示
      marketChannel
        .getScoialUserinfo({
          orgid: this.$store.state.userInfoObj.orgId,
        })
        .then((res) => {
          if (res.result) {
            res.data.list.forEach((element) => {
              element.checked = false;
            });
            this.userData = res.data.list;

            this.userData.forEach((item) => {
              if (item.socialtype === "linkedIn") {
                this.linkedInList.push(item);
              } else if (item.socialtype === "facebook") {
                this.facebookList.push(item);
              } else if (item.socialtype === "twitter") {
                this.twitterList.push(item);
              }
            });
            if (this.linkedInList.length > 0) {
              this.linkedInList[0].checked = true;
            }
            if (this.facebookList.length > 0) {
              this.facebookList[0].checked = true;
            }
            if (this.twitterList.length > 0) {
              this.twitterList[0].checked = true;
            }
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
    },
    getMarketing() {
      marketChannel
        .getScoialMarketingActivity({
          orgid: this.$store.state.userInfoObj.orgId,
        })
        .then((res) => {
          if (res.result) {
            this.campaignList = res.data;
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
    },
    // 锁定当前循环数据
    lockItem(index) {
      this.lockIndex = index;
    },
    // 当前时间发布

    publishNow(index) {
      this.closeMessageDrop(index);
      this.formList[index].selecttime = this.fromViewDate;
      this.formList[index].dateValue = this.fromViewDate;
      this.formList[index].timeValue = "";
    },
    // 多选名字
    fromName(index, item) {
      let str = "";
      this.formList[index].checkedList.forEach((checkItem) => {
        if (checkItem.checked && item.socialtype === checkItem.socialtype) {
          //
          if (str === "") {
            str += checkItem.socialmedianame;
          } else {
            str += "," + checkItem.socialmedianame;
          }
        }
      });
      if (str === "") {
        return "No account selected";
      } else {
        return str;
      }
    },
    // 帖子内部添加发帖
    addNextPost(type) {
      let arr = [];
      let obj = {};
      var j = 0;
      obj = JSON.parse(JSON.stringify(this.formList[this.formList.length - 1]));
      obj.socialtype = type;
      for (let i in this.formList) {
        if (this.formList[i].socialtype == type) {
          arr[j++] = this.formList[i];
        }
      }
      if (arr.length > 0) {
        obj.checkedList = JSON.parse(
          JSON.stringify(arr[arr.length - 1].checkedList)
        );
      } else {
        obj.checkedList = JSON.parse(
          JSON.stringify(
            this.filterSocialUser(
              type,
              true,
              this.formList[this.formList.length - 1].checkedList
            )
          )
        );
      }
      this.formList.push(obj);
      this.formList[this.formList.length - 1].editFlg = true;
      this.close();
    },
    // 筛选可以选择用户

    filterSocialUser(socialType) {
      let list = this.userData.filter((el) => {
        if (socialType === "twitter") {
          return (
            el.socialtype === "twitter" &&
            (el.userdefault === "0" || el.ownerid === this.userId)
          );
        } else {
          return (
            socialType === el.socialtype &&
            (el.userdefault === "0" || el.ownerid === this.userId)
          );
        }
      });
      list[0].checked = true;
      return list;
    },
    // 选择按钮时间

    selectTime(value, index) {
      this.closeMessageDrop(index);
      this.formList[index].sendType = false;
      if (this.formList[index].dateValue !== this.$i18n.t("c807")) {
        this.formList[index].timeValue = value;
      } else {
        let dd = new Date();
        let y = dd.getFullYear();
        let m =
          dd.getMonth() + 1 < 10
            ? "0" + (dd.getMonth() + 1)
            : dd.getMonth() + 1;
        let d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
        // let h = dd.getHours() < 10 ? "0" + dd.getHours() : dd.getHours();
        // let s =
        //   dd.getMinutes() + 10 < 10 ? "0" + dd.getMinutes() : dd.getMinutes();
        this.formList[index].dateValue = `${y}-${m}-${d}`;
        this.formList[index].timeValue = value;
      }
    },
    // 关闭选择下拉
    closeMessageDrop(index) {
      if (this.formList[index].dateValue == null) {
        this.formList[index].dateValue = this.getNowFormatDate();
      }
      this.$refs[`popover-${index}`][0].doClose();
      // this.$refs.messageDrop.forEach((item) => {
      //   item.hide();
      // });
    },
    // 选择特定时间

    fillTime(index) {
      this.closeMessageDrop(index);
      this.formList[index].sendType = false;
      if (this.formList[index].dateValue !== "Posting Now") {
        this.formList[index].timeValue = this.timeSelect;
      } else {
        let dd = new Date();
        let y = dd.getFullYear();
        let m =
          dd.getMonth() + 1 < 10
            ? "0" + (dd.getMonth() + 1)
            : dd.getMonth() + 1;
        let d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
        // let h = dd.getHours() < 10 ? "0" + dd.getHours() : dd.getHours();
        // let s =
        //   dd.getMinutes() + 10 < 10 ? "0" + dd.getMinutes() : dd.getMinutes();
        this.formList[index].dateValue = `${y}-${m}-${d}`;
        this.formList[index].timeValue = this.timeSelect;
      }
      if (!this.showBtList.includes(this.timeSelect)) {
        this.showBtList.push(this.timeSelect);
      }
    },
    discard() {
      this.formList = [];
      this.onceEdit = false;
      this.messageList = [];
      this.drawer = false;
      this.dialogVisible = false;
    },
    saveDrafts: debounce(function (posttype) {
      this.saveLoad = true;
      // 判断市场活动是否选择
      this.getlinkedIdList(posttype);
      this.saveLoad = true;
      if (this.pathType === "edit") {
        // 编辑保存
        this.editPostInfo();
      } else if (this.pathType === "draft") {
        this.editPostInfo("draft");
      } else if (this.pathType === "reschedule") {
        this.editPostInfo();
      } else {
        // 新建保存
        this.saveScoialPoster();
      }
    }, 1000),

    getlinkedIdList(posttype) {
      this.formList.forEach((el) => {
        // 更具日期以及按钮选择判断发送类型
        if (posttype === "send" && el.sendType) {
          el.posttype = "sending";
        } else if (posttype === "drafts") {
          el.posttype = "draft";
        } else {
          el.posttype = "timing";
        }
        // 将发布用户id合并
        let linkedIds = "";
        el.checkedList.forEach((item) => {
          if (item.checked && el.socialtype === item.socialtype) {
            linkedIds += item.socialmediaid + ",";
          }
        });

        if (linkedIds.length > 0) {
          linkedIds = linkedIds.substr(0, linkedIds.length - 1);
        }
        el.linkedIdList = linkedIds;
        // 时间整合
        let timingdate = "";
        if (el.dateValue === this.fromViewDate) {
          let newDate = new Date();
          let Y = newDate.getFullYear();
          let M = this.fillZero(newDate.getMonth() + 1);
          let D = this.fillZero(newDate.getDate());
          let H = this.fillZero(newDate.getHours());
          let I = this.fillZero(newDate.getMinutes());
          let S = this.fillZero(newDate.getSeconds());
          timingdate = Y + "-" + M + "-" + D + " " + H + ":" + I + ":" + S;
        } else {
          timingdate = el.dateValue + " " + el.timeValue;
        }
        el.timingdate = timingdate;
        // 转换换行符和空格
        // el.contents = el.contents
        //   .replace(/<br\/>/g, "\n")
        //   .replace(/&nbsp;/g, " ");
        // 去除标签
        el.contents = el.contents.replace(/<\/?.+?>/g, "");
      });
    },
    //月日时分秒为单位时前面补零
    fillZero(v) {
      if (v < 10) {
        v = "0" + v;
      }
      return v;
    },

    editPostInfo(type) {
      let socialdata = [];
      this.formList.forEach((el) => {
        let obj = {
          socialtype: el.socialtype,
          socialmediaids: el.linkedIdList,
          timingdate: el.dateValue + " " + el.timeValue + ":00",
          textcontent: el.contents,
          linkurls: el.linkurls,
          filecontentids: el.filecontentid,
          poststype: type ? "timing" : el.posttype,
          postsid: el.postsid,
        };
        socialdata.push(obj);
      });
      let saveData = {
        orgid: this.$store.state.userInfoObj.orgId,
        campaignid: this.campainSelect,
        socialdata: socialdata,
      };
      marketChannel.editPostInfo(saveData).then((res) => {
        this.saveLoad = false;
        if (res.result) {
          let flag = true;
          if (res.data) {
            res.data.forEach((el) => {
              if (el.type === "fail") {
                flag = false;
              }
            });
          }
          if (flag) {
            this.messageList = res.data;
            this.messageBoxFlg = true;
            setTimeout(() => {
              this.messageBoxFlg = false;
            }, 5000);
          } else {
            this.messageList = res.data;
            this.messageBoxFlg = true;
            setTimeout(() => {
              this.messageBoxFlg = false;
            }, 5000);
            this.refreshList();
          }
          this.refreshList();
          this.dialogVisible = false;
          this.errMessage = [];
          this.formList = [];
          this.onceEdit = false;
          this.drawer = false;
        } else {
          this.messageList = res.data;
          this.messageBoxFlg = true;
          setTimeout(() => {
            this.messageBoxFlg = false;
          }, 5000);
          this.refreshList();
        }
        this.saveLoad = false;
      });
    },

    saveScoialPoster() {
      let socialdata = [];
      this.formList.forEach((el) => {
        let obj = {
          socialtype: el.socialtype,
          socialmediaids: el.linkedIdList,
          timingdate: el.dateValue + " " + el.timeValue + ":00",
          textcontent: el.contents,
          linkurls: el.linkurls,
          filecontentids: el.filecontentid,
          poststype: el.posttype,
        };
        socialdata.push(obj);
      });
      let saveData = {
        orgid: this.$store.state.userInfoObj.orgId,
        campaignid: this.campainSelect,
        socialdata: socialdata,
      };
      this.messageList = [];
      marketChannel.savePostsInfo(saveData).then((res) => {
        this.saveLoad = false;
        if (res.result) {
          let flag = true;
          if (res.data) {
            res.data.forEach((el) => {
              if (el.type === "fail") {
                flag = false;
              }
            });
          }
          if (flag) {
            this.messageList = res.data;
            this.messageBoxFlg = true;
            setTimeout(() => {
              this.messageBoxFlg = false;
            }, 5000);
          } else {
            this.messageList = res.data;
            this.messageBoxFlg = true;
            setTimeout(() => {
              this.messageBoxFlg = false;
            }, 5000);
            this.refreshList();
          }
          this.refreshList();
          this.dialogVisible = false;
          this.errMessage = [];
          this.formList = [];
          this.onceEdit = false;
          this.drawer = false;
        } else {
          this.messageList = res.data;
          this.messageBoxFlg = true;
          setTimeout(() => {
            this.messageBoxFlg = false;
          }, 5000);
          this.refreshList();
        }
        this.saveLoad = false;
      });
    },
    continueEdit() {
      this.dialogVisible = false;
      this.drawer = true;
    },

    changFormList(pushList) {
      this.formList.push(pushList);
    },

    onContentChange(contents, index) {
      this.formList[index].contents = contents;
    },
    changeEditFlg(content, index) {
      this.formList[index].editFlg = !this.formList[index].editFlg;
    },
    // 头像显示flg
    srcImg(flg) {
      if (flg === "facebook") {
        return this.faceImg;
      } else if (flg === "twitter") {
        return this.twitterImg;
      } else {
        return this.inImg;
      }
    },
    // 删除
    detelePsot(index, flg) {
      if (this.pathType !== "edit" && this.pathType !== "draft") {
        if (!flg) {
          if (this.formList.length === 1) {
            this.discard();
            this.$emit("newSocial");
          } else {
            this.formList.splice(index, 1);
            this.errMessage.splice(index, 1);
          }
          this.setBtTitle();
        }
      }
    },
    handleCommand() {
      // this.Daterange = command;
    },
    handleClose() {
      this.dialogVisible = true;
    },
    // chatInput 输入事件
    // chatInput(content, index) {
    //   this.$set(this.formList[index], "contents", content);
    //   // this.formList[index].contents = content;
    // },
    // 添加链接事件
    linkUpload(url, index) {
      this.formList[index].linkurls[0].originalurl = url.url;
      this.formList[index].linkurls[0].titletext = url.name;
    },
    //清除下拉框
    clearBtnDialog(index) {
      this.formList[index].formsPopFlg = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.errMessage {
  .errSvg {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    left: 30px;
    z-index: 999;
  }
  .errLine {
  }
}
.messageBox {
  width: 30%;
  min-height: 100px;
  background-color: #d8f1ef;
  position: fixed;
  top: 5%;
  left: 40%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  // padding-left: 15%;
  align-items: center;
  flex-direction: column;
  i {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
  }
}
.dropBtBox {
  padding: 10px 0px;
  margin-left: 20px;
}
// 预览链接样式
.linksEditor {
  padding: 10px 0px;
  .linksBox {
    background-color: #ccc;
    margin: 15px 40px;
    min-height: 100px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    .el-icon-close:before {
      content: "\e6db";
      float: right;
      margin-right: 10px;
      cursor: pointer;
    }
    .linkUrl {
      font-size: 14px;
      text-align: center;
      word-break: break-word;
    }
    .linksTitle {
      width: 80%;
      margin-left: 10%;
    }
  }
}
.icon {
  display: inline-block;
}
.line_title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 170px;
  display: inline-block;
  font-family: Helvetica;
  font-size: 14px;
  color: #0067c9;
  letter-spacing: 0;
  line-height: 50px;
  vertical-align: bottom;
}
::v-deep .el-dialog__body {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 14px !important;
    font-weight: normal !important;
    font-family: MicrosoftYaHei;
    color: #080707;
    letter-spacing: 0;
  }
}
::v-deep .el-button--mini {
  padding: 7px 10px;
}
.customTime {
  width: 500px;
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  .dateView {
    padding: 20px;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    span {
      height: 30px;
      line-height: 30px;
      font-family: Helvetica;
      font-size: 14px;
      color: #3e3c3c;
      letter-spacing: 0;
      font-weight: bold;
    }
  }
  .selectDate {
    padding: 20px;
    .inputTime {
      display: flex;
      justify-content: space-between;
    }
    .selectBt {
      margin-top: 10px;
      display: flex;
      width: 200px;
      justify-content: space-between;
      flex-wrap: wrap;
      .timeBt {
        font-family: MicrosoftYaHei;
        font-size: 10px;
        color: #3e3c3c;
        margin: 5px 0px;
      }
    }
    span {
      height: 30px;
      line-height: 30px;
      font-family: Helvetica;
      font-size: 14px;
      color: #3e3c3c;
      letter-spacing: 0;
      font-weight: bold;
    }
    a {
      display: block;
      height: 40px;
      line-height: 40px;
      font-family: Helvetica;
      font-size: 14px;
      color: #006dcc;
      letter-spacing: 0;
    }
  }
  .showDate {
    margin-left: 295px;
    padding: 20px;
    .selectBt {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      .timeBt {
        font-family: MicrosoftYaHei;
        font-size: 10px;
        color: #3e3c3c;
        margin: 5px 0px;
      }
    }
    .nowBt {
      width: 100%;
      margin-bottom: 20px;
    }
    span {
      height: 30px;
      line-height: 30px;
      font-family: Helvetica;
      font-size: 14px;
      color: #3e3c3c;
      letter-spacing: 0;
      font-weight: bold;
    }
    a {
      display: block;
      height: 40px;
      line-height: 40px;
      font-family: Helvetica;
      font-size: 14px;
      color: #006dcc;
      letter-spacing: 0;
    }
  }
}
.postingBox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.postingCheck {
  margin: 10px;
  img {
    margin: 0px 5px 2px 0px;
    width: 20px;
    height: 20px;
  }
  .mainImg {
    width: 20px;
    height: 20px;
    vertical-align: bottom;
  }
  margin-left: 20px;
}
::v-deep .el-dropdown-menu {
  z-index: 9999;
}

.dropTitle {
  font-weight: bold;
  font-size: 18px;
  padding-left: 20px;
}

.content {
  height: 85%;
}
.campagin {
  width: 94.5%;
  float: right;
  padding: 20px 30px 10px 45px;
  .mes {
    height: 40px;
    line-height: 40px;
  }
  .select {
    border-radius: 3px;
    width: 712px;
  }
}
::v-deep .el-scrollbar__wrap {
  width: 700px;
  overflow: scroll;
  height: 100%;
}
.addPost {
  width: 94.5%;
  height: 40px;
  float: right;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 45px;
  position: relative;
  .addImg {
    position: absolute;
    left: -20px;
    width: 40px;
    height: 40px;
    color: #888888;
    border-radius: 50%;
    border: 1px solid #888888;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 25px;
    }
  }
  .addMes {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #333333;
    letter-spacing: 0;
  }
  .img {
    margin: 0px 10px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}
.postForm {
  position: relative;
  width: 94.5%;
  float: right;
  padding: 10px 30px 10px 45px;
  .aside {
    position: absolute;
    left: -20px;
    top: 20px;
    background: #f0f0f0;
    .asideImg {
      width: 40px;
      height: 40px;
    }
  }
  .showPost {
    border-radius: 3px;
    border-radius: 3px;
    width: 100%;
    min-height: 200px;
    .edit-postBt {
      height: 50px;
      border-top: 1px solid #dedcda;
      padding: 10px;
      .editPostBt {
        float: right;
        color: #006dcc;
        letter-spacing: 0;
        text-align: center;
      }
    }
    .show-headerMes {
      padding: 20px 53px 25px 16px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #888888;
      letter-spacing: 0;
      a {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 19px;
        margin-right: 5px;
      }
    }
    .show-mainMes {
      border: 1px solid #dddbda;
      width: 95%;
      margin: 0px auto 20px;
      min-height: 50px;
      padding: 10px;
      .email-prefix {
        font-family: SourceHanSansCN-Bold;
        font-size: 14px;
        color: #080707;
        letter-spacing: 0;
        font-weight: bold;
      }
      .email-suffix {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #3a3a3a;
        letter-spacing: 0;
        line-height: 21px;
      }
      .post-img-box {
        display: flex;
        width: 30%;
        justify-content: space-around;
      }
    }
  }
  .eidterBox {
    // position: relative;
    .linksPop {
      position: absolute;
      width: 300px;
      height: 220x;
      border: 1px solid #ccc;
      box-shadow: 1px 1px 2px #ccc;
      color: #333;
      background-color: #fff;
      z-index: 3;
      top: 40px;
      left: 0px;
      padding: 20px;
      p {
        margin: 10px 0px;
      }
      .linksInput {
        width: 200px;
        margin-top: 10px;
      }
      .linkAction {
        display: block;
        text-align: right;
        margin-top: 10px;
        cursor: pointer;
      }
      .linksClose {
        position: absolute;
        right: 0;
        top: 0;
        padding: 5px;
        margin: 2px 5px 0 0;
        cursor: pointer;
        color: #999;
        content: "\f00d";
      }
    }
    .formPop {
      position: absolute;
      padding: 20px;
      border: 1px solid #ccc;
      box-shadow: 1px 1px 2px #ccc;
      color: #333;
      background-color: #fff;
      z-index: 3;
      top: 40px;
      left: 0px;
      width: 200px;
      .formPopTitle {
        margin: 10px 0px;
      }
      .formPopBt {
        margin-top: 20px;
      }
      .linksClose {
        position: absolute;
        right: 0;
        top: 0;
        padding: 5px;
        margin: 2px 5px 0 0;
        cursor: pointer;
        color: #999;
        content: "\f00d";
      }
    }
  }
  .post {
    background: #ffffff;
    // border: 1px solid #dedcda;
    border-radius: 3px;
    border-radius: 3px;
    width: 100%;

    .aside-warning {
      position: absolute;
      z-index: 4;
      transform: translate(-50%, -50%);
    }
    .dropLeft {
      position: relative;
      padding-left: 16px;
      width: 50%;
      display: inline-block;
      height: 50px;
      line-height: 50px;
      background: #ffffff;
      border-right: 1px solid #dedcda;
      border-radius: 3px;
      border-radius: 3px;
      i {
        margin: 17px 5px;
      }
    }
    .dropRight {
      position: relative;
      padding-left: 16px;
      width: 50%;
      display: inline-block;
      height: 50px;
      line-height: 50px;
      background: #ffffff;
      border-radius: 3px;
      border-radius: 3px;
      i {
        margin: 17px 5px;
      }
    }
  }
}
.dropWhen {
  padding: 10px 0px;
  margin-left: 20px;
  width: 600px;
}
.waring {
  width: 94.5%;
  height: 100px;
  float: right;
  border-left: 1px solid #dedcda;
  padding: 35px 30px 10px 45px;
  .waringMes {
    width: 100%;
    background: #fff6eb;
    border: 1px solid #e5851a;
    border-radius: 3px;
    border-radius: 3px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    .msg {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #000000;
      letter-spacing: 0;
      line-height: 17px;
    }
  }
}
.bottomBox {
  height: 90px;
  padding: 25px;
  width: 100%;
  display: inline-block;
  border-top: 1px solid #dbd9d7;
  position: relative;
  .postBt {
    // width: 155px;
    // cursor: pointer;
    // height: 44px;
    // border-radius: 3px;
    // border-radius: 3px;
    // font-family: MicrosoftYaHei;
    // font-size: 14px;
    // letter-spacing: 0;
    // text-align: center;
  }
  .availableImg {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #c23934;
    text-align: center;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
  }
}
::v-deep .el-drawer__body {
  background: #f0f0f0;
}
::v-deep .el-drawer__header {
  background: linear-gradient(90deg, #2d6cfc 0%, #6c98fd 100%);
  font-family: Helvetica;
  font-size: 18px;
  color: #fff;
  letter-spacing: 0;
  font-weight: bold;
  padding: 20px;
  margin-bottom: 0px;
}
::v-deep .editorImgContent {
  display: flex;
  justify-content: flex-start;
  padding: 15px 0px 0px;
  .inner {
    justify-content: space-around;
    display: flex;
    position: relative;
    .imgColumn {
      display: flex;
      justify-content: space-around;
      position: absolute;
      width: 50px;
      top: -15px;
      left: 280px;
      i {
        cursor: pointer;
      }
    }
    .editorImg {
      margin: 5px;
    }
    .editorImg:hover {
      box-shadow: 2px 2px 2px 2px #ccc;
    }
  }
}

.lcoation {
  display: flex;
  align-items: center;
}
.close {
  display: flex;
  align-items: center;
  svg {
    width: 14px;
    height: 14px;
  }
}
</style>
